<template>
  <div class="query_wrapper container">
    <el-form ref="form" :model="form" label-width="72px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩类型">
            <el-select
              style="width: 100%"
              v-model="form.contractTypeList"
              placeholder="请选择业绩类型"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in certTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设性质">
            <el-select
              style="width: 100%"
              v-model="form.constructTypeList"
              placeholder="请选择建设性质"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in constructionNatureOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="工程用途">
            <el-select
              style="width: 100%"
              v-model="form.engineeringUseList"
              placeholder="请选择工程用途"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in engineeringUseOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目分类">
            <el-select
              style="width: 100%"
              v-model="form.resultTypeList"
              placeholder="请选择项目分类"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in projectClassOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="相关单位">
            <el-input
              placeholder="请输入单位名称"
              v-model.trim="form.participateCompanyName"
              class="input-with-select"
              clearable
            >
              <el-select
                style="width: 150px"
                v-model="form.participateCompanyRole"
                slot="prepend"
                placeholder="请选择企业角色"
                clearable
              >
                <el-option
                  v-for="item in corporateRoleOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设单位">
            <el-input
              placeholder="请输入建设单位"
              v-model.trim="form.ownerName"
              class="input-with-select"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="12">
          <el-form-item label="相关人员">
            <el-input
              placeholder="请输入相关人员"
              v-model.trim="form.companyName"
              class="input-with-select"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-col> -->

        <el-col :span="12">
          <el-form-item label="排序">
            <el-select
              style="width: 100%"
              v-model="form.sort"
              placeholder="请选择排序"
              clearable
            >
              <el-option
                v-for="item in fourthSortOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="关键词" name="1">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="数据来源">
                <el-select
                  style="width: 100%"
                  v-model="form.proKwSoureList"
                  placeholder="请选择数据来源"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in keyWordDataSourceOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑">
                <el-select
                  style="width: 100%"
                  v-model="form.proKwFilterLogic"
                  placeholder="请先选择数据来源后再选择筛选逻辑"
                  clearable
                  :disabled="
                    !form.proKwSoureList || form.proKwSoureList.length == 0
                  "
                >
                  <el-option
                    v-for="item in keyWordFilterLogicOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="关键词" style="margin-bottom: 0px">
                <el-row
                  class="mb22"
                  v-for="(item, index) in keywordList"
                  :key="'project' + index"
                >
                  <el-col :span="21">
                    <el-input
                      placeholder="请输入关键词"
                      v-model.trim="item.keyword"
                      class="input-with-select"
                      clearable
                    >
                      <el-select
                        style="width: 100px"
                        v-model="item.radio"
                        slot="prepend"
                        placeholder="请选择"
                        clearable
                      >
                        <el-option label="包含" value="包含"></el-option>
                        <el-option label="不包含" value="不包含"></el-option>
                      </el-select>
                    </el-input>
                  </el-col>

                  <el-col :span="3" class="tac">
                    <el-button
                      v-if="index == 0"
                      type="primary"
                      icon="el-icon-plus"
                      circle
                      plain
                      @click="addName"
                    >
                    </el-button>

                    <el-button
                      v-else
                      @click="delName(index)"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      plain
                    ></el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="金额" name="2">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="数据来源">
                <el-select
                  style="width: 100%"
                  v-model="form.amountSoureList"
                  placeholder="请选择数据来源"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in amountDataSourceOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑">
                <el-select
                  style="width: 100%"
                  v-model="form.amountFilterLogic"
                  placeholder="请先选择数据来源后再选择筛选逻辑"
                  clearable
                  :disabled="
                    !form.amountSoureList || form.amountSoureList.length == 0
                  "
                >
                  <el-option
                    v-for="item in amountFilterLogicOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="业绩金额">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="时间" name="3">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="中标时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.resultTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="合同时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.contractTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="施工许可时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.permitTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="审查时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.reviewTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="计划开工时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.planStartTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions1"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="计划竣工时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.planEndTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions1"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="实际开工时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.actualStartTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions1"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="实际竣工时间" label-width="96px">
                <el-date-picker
                  class="flex1"
                  v-model="time.actualCompleteTime"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions1"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑" label-width="96px">
                <el-select
                  style="width: 100%"
                  v-model="form.dateFilterLogic"
                  placeholder="请选择筛选逻辑"
                  clearable
                >
                  <el-option
                    v-for="item in filterLogicOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <!-- <el-tab-pane label="项目参数" name="4">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="建筑面积/平方米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="建筑高度/米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="建筑层数/层" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="跨度/米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="地下层数/层" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="道路长度/千米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="长度/千米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="道路等级" label-width="135px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择道路等级"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="桥梁长度/米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="桥梁跨径/米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="隧道长度/米" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="日处理量/立方米/天" label-width="135px">
                <div class="flex alc flex1">
                  <el-input
                    v-model.number.trim="form.minAmount"
                    placeholder="最小值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>

                  <span class="ml10 mr10">至</span>

                  <el-input
                    v-model.number.trim="form.maxAmount"
                    placeholder="最大值"
                    clearable
                  >
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="结构体系" label-width="135px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择结构体系"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="参数逻辑" label-width="135px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择参数逻辑"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="数据来源" label-width="135px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择数据来源"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑" label-width="135px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择筛选逻辑"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane> -->

        <el-tab-pane label="项目经理" name="5">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="项目经理">
                <el-input
                  placeholder="请输入建设单位"
                  v-model.trim="form.proManagerName"
                  class="input-with-select"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="来源栏目">
                <el-select
                  style="width: 100%"
                  v-model="form.proManagerSourceList"
                  placeholder="请选择来源栏目"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in managerDataSourceOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑">
                <el-select
                  style="width: 100%"
                  v-model="form.proManagerFilterLogic"
                  placeholder="请先选择来源栏目后再选择筛选逻辑"
                  clearable
                  :disabled="
                    !form.proManagerSourceList ||
                    form.proManagerSourceList.length === 0
                  "
                >
                  <el-option
                    v-for="item in managerFilterLogicOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <!-- <el-tab-pane label="项目环节" name="6">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="来源栏目">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择来源栏目"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="存在企业名称" label-width="96px">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择存在企业名称"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑">
                <el-select
                  style="width: 100%"
                  v-model="form.stateList"
                  placeholder="请选择筛选逻辑"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in temp"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane> -->

        <el-tab-pane label="数据等级" name="7">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="数据等级">
                <el-select
                  style="width: 100%"
                  v-model="form.dataLevel"
                  placeholder="请选择数据等级"
                  clearable
                >
                  <el-option
                    v-for="item in dataLevelOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="来源栏目">
                <el-select
                  style="width: 100%"
                  v-model="form.dataLevelSoureList"
                  placeholder="请选择来源栏目"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in dataLevelDataLevelOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="筛选逻辑">
                <el-select
                  style="width: 100%"
                  v-model="form.dataLevelFilterLogic"
                  placeholder="请先选择来源栏目后再选择筛选逻辑"
                  clearable
                  :disabled="
                    !form.dataLevelSoureList ||
                    form.dataLevelSoureList.length === 0
                  "
                >
                  <el-option
                    v-for="item in dataFilterLogicOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <el-row class="mt20">
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          条业绩信息
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name link" @click="toDetail(item.basicId)">
            {{ item.name }}
          </div>

          <el-row class="desc mb20">
            <el-col
              :span="8"
              class="desc_item"
              :title="'建设单位：' + item.ownerName"
            >
              <div class="left_text">建设单位：</div>
              <div class="right_content">
                {{ item.ownerName }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '项目地区：' +
                (item.location && item.location != '-1' ? item.location : '--')
              "
            >
              <div class="left_text">项目地区：</div>
              <div class="right_content">
                {{ item.location | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '建设性质：' +
                (item.constructType && item.constructType != '-1'
                  ? item.constructType
                  : '--')
              "
            >
              <div class="left_text">建设性质：</div>
              <div class="right_content">
                {{ item.constructType | noVoid }}
              </div>
            </el-col>
          </el-row>
          <el-row class="desc">
            <el-col
              :span="8"
              class="desc_item"
              :title="
                '总投资额：' +
                (item.totalAmount && item.totalAmount != '-1'
                  ? item.totalAmount
                  : '--')
              "
            >
              <div class="left_text">总投资额：</div>
              <div class="right_content">
                {{ item.totalAmount | noVoid }}
                万元
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '计划开工时间：' +
                (item.planStartTime && item.planStartTime != '-1'
                  ? item.planStartTime
                  : '--')
              "
            >
              <div class="left_text">计划开工时间：</div>
              <div class="right_content">
                {{ item.planStartTime | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '工程用途：' +
                (item.engineeringUse && item.engineeringUse != '-1'
                  ? item.engineeringUse
                  : '--')
              "
            >
              <div class="left_text">工程用途：</div>
              <div class="right_content">
                {{ item.engineeringUse | noVoid }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查业绩 */
// 引入ajax函数
import { querySikuyiBasicPage } from "@/api/fourthLibrary.js";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
import queryTypeTips from "@/views/itemizedQuery/components/queryTypeTips.vue";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        location: "", // 企业地区,
        contractTypeList: [], // 业绩类型
        constructTypeList: [], // 建设性质
        engineeringUseList: [], // 工程用途
        resultTypeList: [], // 项目分类
        participateCompanyRole: "", // 相关单位-企业角色
        participateCompanyName: "", // 相关单位-单位名称
        ownerName: "", // 建设单位
        sort: "", // 排序方式
        contProKwList: [], // 关键词-包含
        exclProKwList: [], // 关键词-不包含
        proKwSoureList: [], // 关键词-数据来源
        proKwFilterLogic: "", // 关键词-筛选逻辑
        amountSoureList: [], // 金额-数据来源
        amountFilterLogic: "", // 金额-筛选逻辑
        minAmount: "", // 最小金额
        maxAmount: "", // 最大金额
        // isAmountContainNull: false, // 金额是否包含空值
        proManagerName: "", // 项目经理姓名
        proManagerSourceList: [], // 项目经理-来源栏目
        proManagerFilterLogic: "", // 项目经理-筛选逻辑
        dataLevel: "", // 数据等级
        dataLevelSoureList: [], // 数据等级-来源栏目
        dataLevelFilterLogic: "", // 数据等级-筛选逻辑

        minResultTime: "", // 最小中标时间
        maxResultTime: "", // 最大中标时间
        minContractTime: "", // 最小合同时间
        maxContractTime: "", // 最大合同时间
        minPermitTime: "", // 最小施工许可时间
        maxPermitTime: "", // 最大施工许可时间
        minReviewTime: "", // 最小审查时间
        maxReviewTime: "", // 最大审查时间
        minPlanStartTime: "", // 最小计划开工时间
        maxPlanStartTime: "", // 最大计划开工时间
        minPlanEndTime: "", // 最小计划竣工时间
        maxPlanEndTime: "", // 最大计划竣工时间
        minActualStartTime: "", // 最小实际开工时间
        maxActualStartTime: "", // 最大实际开工时间
        minActualCompleteTime: "", // 最小实际竣工时间
        maxActualCompleteTime: "", // 最大实际竣工时间
        dateFilterLogic: "", // 时间筛选逻辑
        source_desc: "全国建筑市场监管公共服务平台", // 数据来源描述

        pageNum: 1,
        pageSize: 20,
      },

      areaOptions: [], // 地区选项数据
      certTypeOptions: [], // 业绩类型数据
      constructionNatureOptions: [], // 建设性质数据
      engineeringUseOptions: [], // 工程用途数据
      projectClassOptions: [], // 项目分类数据
      corporateRoleOptions: [], // 企业角色数据
      fourthSortOptions: [], // 排序数据
      keyWordDataSourceOptions: [], // 关键词-数据来源数据
      amountDataSourceOptions: [], // 金额-数据来源数据
      managerDataSourceOptions: [], // 项目经理-来源栏目数据
      linkDataSourceOptions: [], // 环节-来源栏目数据
      linkExistNameOptions: [], // 环节-存在企业名称数据
      linkFilterLogicOptions: [], // 环节-筛选逻辑数据
      dataLevelOptions: [], // 数据等级-数据等级数据
      dataLevelDataLevelOptions: [], // 数据等级-来源栏目数据
      // 关键词
      keywordList: [
        {
          radio: "",
          keyword: "",
        },
      ],
      time: {
        resultTime: [], // 中标时间
        contractTime: [], // 合同时间
        permitTime: [], // 施工许可时间
        reviewTime: [], // 审查时间
        planStartTime: [], // 计划开工时间
        planEndTime: [], // 计划竣工时间
        actualStartTime: [], // 实际开工时间
        actualCompleteTime: [], // 实际竣工时间
      },
      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 时间选择器快捷选项-不禁止选择今日之后的时间
      pickerOptions1: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      activeName: "1",
    };
  },

  computed: {
    // 关键词-筛选逻辑数据
    keyWordFilterLogicOptions() {
      let len = this.form.proKwSoureList.length;
      if (len > 0) {
        let temp = [];
        temp = this.form.proKwSoureList.map((v, i) => {
          return {
            name: `任意符合${i + 1}种栏目即可`,
            value: i + 1,
          };
        });
        temp.splice(len - 1, 1, { name: "同时符合所有栏目", value: len });
        return temp;
      } else {
        return [];
      }
    },
    // 金额-筛选逻辑数据
    amountFilterLogicOptions() {
      let len = this.form.amountSoureList.length;
      if (len > 0) {
        let temp = [];
        temp = this.form.amountSoureList.map((v, i) => {
          return {
            name: `任意符合${i + 1}种栏目即可`,
            value: i + 1,
          };
        });
        temp.splice(len - 1, 1, { name: "同时符合所有栏目", value: len });

        return temp;
      } else {
        return [];
      }
    },
    // 时间筛选逻辑选择器选项
    filterLogicOptions() {
      const obj = { ...this.time };
      const keys = Object.keys(obj);
      const vals = keys.filter((v) => obj[v] && obj[v].length > 0);
      const len = vals.length;
      if (len > 0) {
        let temp = [];
        for (let index = 0; index < len - 1; index++) {
          temp.push({
            name: `任意符合${index + 1}种时间即可`,
            value: index + 1,
          });
        }
        temp.push({ name: "同时符合所有时间", value: len });
        return temp;
      } else {
        return [];
      }
    },
    // 项目经理-筛选逻辑数据
    managerFilterLogicOptions() {
      let len = this.form.proManagerSourceList.length;
      if (len > 0) {
        let temp = [];
        temp = this.form.proManagerSourceList.map((v, i) => {
          return {
            name: `任意符合${i + 1}种栏目即可`,
            value: i + 1,
          };
        });
        temp.splice(len - 1, 1, { name: "同时符合所有栏目", value: len });

        return temp;
      } else {
        return [];
      }
    },
    // 数据等级-筛选逻辑数据
    dataFilterLogicOptions() {
      let len = this.form.dataLevelSoureList.length;
      if (len > 0) {
        let temp = [];
        temp = this.form.dataLevelSoureList.map((v, i) => {
          return {
            name: `任意符合${i + 1}种栏目即可`,
            value: i + 1,
          };
        });
        temp.splice(len - 1, 1, { name: "同时符合所有栏目", value: len });

        return temp;
      } else {
        return [];
      }
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 处理查询参数
    handleParams() {
      const location = this.form.location
        ? this.form.location.filter((v) => v != "").join("-")
        : "";
      let minResultTime = ""; // 最小中标时间
      let maxResultTime = ""; // 最大中标时间
      let minContractTime = ""; // 最小合同时间
      let maxContractTime = ""; // 最大合同时间
      let minPermitTime = ""; // 最小施工许可时间
      let maxPermitTime = ""; // 最大施工许可时间
      let minReviewTime = ""; // 最小审查时间
      let maxReviewTime = ""; // 最大审查时间
      let minPlanStartTime = ""; // 最小计划开工时间
      let maxPlanStartTime = ""; // 最大计划开工时间
      let minPlanEndTime = ""; // 最小计划竣工时间
      let maxPlanEndTime = ""; // 最大计划竣工时间
      let minActualStartTime = ""; // 最小实际开工时间
      let maxActualStartTime = ""; // 最大实际开工时间
      let minActualCompleteTime = ""; // 最小实际竣工时间
      let maxActualCompleteTime = ""; // 最大实际竣工时间

      if (this.time.resultTime.length > 0) {
        minResultTime = this.time.resultTime[0];
        maxResultTime = this.time.resultTime[1];
      }
      if (this.time.contractTime.length > 0) {
        minContractTime = this.time.contractTime[0];
        maxContractTime = this.time.contractTime[1];
      }
      if (this.time.permitTime.length > 0) {
        minPermitTime = this.time.permitTime[0];
        maxPermitTime = this.time.permitTime[1];
      }
      if (this.time.reviewTime.length > 0) {
        minReviewTime = this.time.reviewTime[0];
        maxReviewTime = this.time.reviewTime[1];
      }
      if (this.time.planStartTime.length > 0) {
        minPlanStartTime = this.time.planStartTime[0];
        maxPlanStartTime = this.time.planStartTime[1];
      }
      if (this.time.planEndTime.length > 0) {
        minPlanEndTime = this.time.planEndTime[0];
        maxPlanEndTime = this.time.planEndTime[1];
      }
      if (this.time.actualStartTime.length > 0) {
        minActualStartTime = this.time.actualStartTime[0];
        maxActualStartTime = this.time.actualStartTime[1];
      }
      if (this.time.actualCompleteTime.length > 0) {
        minActualCompleteTime = this.time.actualCompleteTime[0];
        maxActualCompleteTime = this.time.actualCompleteTime[1];
      }

      const contProKwList = this.keywordList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword);
      const exclProKwList = this.keywordList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword);

      const params = {
        ...this.form,
        location,
        contProKwList,
        exclProKwList,
        minResultTime, // 最小中标时间
        maxResultTime, // 最大中标时间
        minContractTime, // 最小合同时间
        maxContractTime, // 最大合同时间
        minPermitTime, // 最小施工许可时间
        maxPermitTime, // 最大施工许可时间
        minReviewTime, // 最小审查时间
        maxReviewTime, // 最大审查时间
        minPlanStartTime, // 最小计划开工时间
        maxPlanStartTime, // 最大计划开工时间
        minPlanEndTime, // 最小计划竣工时间
        maxPlanEndTime, // 最大计划竣工时间
        minActualStartTime, // 最小实际开工时间
        maxActualStartTime, // 最大实际开工时间
        minActualCompleteTime, // 最小实际竣工时间
        maxActualCompleteTime, // 最大实际竣工时间
      };

      return params;
    },
    // 获取列表数据
    async getData() {
      this.loading = true;
      const params = this.handleParams();
      const { code, data } = await querySikuyiBasicPage(params);
      if (code !== 0) return;
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 基本信息-业绩类型
      const certTypeOptions = await getDict({ source: "certType" });
      this.certTypeOptions = certTypeOptions;

      // 基本信息-建设性质
      const constructionNatureOptions = await getDict({
        source: "constructionNature",
      });
      this.constructionNatureOptions = constructionNatureOptions;

      // 基本信息-工程用途
      const engineeringUseOptions = await getDict({ source: "engineeringUse" });
      this.engineeringUseOptions = engineeringUseOptions;

      // 基本信息-项目分类
      const projectClassOptions = await getDict({ source: "projectClass" });
      this.projectClassOptions = projectClassOptions;

      // 基本信息-企业角色
      const corporateRoleOptions = await getDict({ source: "corporateRole" });
      this.corporateRoleOptions = corporateRoleOptions;

      // 基本信息-排序
      const fourthSortOptions = await getDict({ source: "fourthSort" });
      this.fourthSortOptions = fourthSortOptions;

      // 关键词-数据来源
      const keyWordDataSourceOptions = await getDict({
        source: "keyWordDataSource",
      });
      this.keyWordDataSourceOptions = keyWordDataSourceOptions;

      // 金额-数据来源
      const amountDataSourceOptions = await getDict({
        source: "amountDataSource",
      });
      this.amountDataSourceOptions = amountDataSourceOptions;

      // 项目经理-来源栏目
      const managerDataSourceOptions = await getDict({
        source: "managerDataSource",
      });
      this.managerDataSourceOptions = managerDataSourceOptions;

      // 环节-来源栏目
      const linkDataSourceOptions = await getDict({ source: "linkDataSource" });
      this.linkDataSourceOptions = linkDataSourceOptions;

      // 数据等级-数据等级
      const dataLevelOptions = await getDict({ source: "dataLevel" });
      this.dataLevelOptions = dataLevelOptions;

      // 数据等级-来源栏目
      const dataLevelDataLevelOptions = await getDict({
        source: "dataLevelDataLevel",
      });
      this.dataLevelDataLevelOptions = dataLevelDataLevelOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "fourthLibraryDetails",
        params: { id },
      });
    },
    // 添加关键词
    addName() {
      if (this.keywordList.length < 5) {
        this.keywordList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除关键词
    delName(index) {
      this.keywordList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: "", // 企业地区,
        contractTypeList: [], // 业绩类型
        constructTypeList: [], // 建设性质
        engineeringUseList: [], // 工程用途
        resultTypeList: [], // 项目分类
        participateCompanyRole: "", // 相关单位-企业角色
        participateCompanyName: "", // 相关单位-单位名称
        ownerName: "", // 建设单位
        sort: "", // 排序方式
        contProKwList: [], // 关键词-包含
        exclProKwList: [], // 关键词-不包含
        proKwSoureList: [], // 关键词-数据来源
        proKwFilterLogic: "", // 关键词-筛选逻辑
        amountSoureList: [], // 金额-数据来源
        amountFilterLogic: "", // 金额-筛选逻辑
        minAmount: "", // 最小金额
        maxAmount: "", // 最大金额
        // isAmountContainNull: false, // 金额是否包含空值
        proManagerName: "", // 项目经理姓名
        proManagerSourceList: [], // 项目经理-来源栏目
        proManagerFilterLogic: "", // 项目经理-筛选逻辑
        dataLevel: "", // 数据等级
        dataLevelSoureList: [], // 数据等级-来源栏目
        dataLevelFilterLogic: "", // 数据等级-筛选逻辑

        minResultTime: "", // 最小中标时间
        maxResultTime: "", // 最大中标时间
        minContractTime: "", // 最小合同时间
        maxContractTime: "", // 最大合同时间
        minPermitTime: "", // 最小施工许可时间
        maxPermitTime: "", // 最大施工许可时间
        minReviewTime: "", // 最小审查时间
        maxReviewTime: "", // 最大审查时间
        minPlanStartTime: "", // 最小计划开工时间
        maxPlanStartTime: "", // 最大计划开工时间
        minPlanEndTime: "", // 最小计划竣工时间
        maxPlanEndTime: "", // 最大计划竣工时间
        minActualStartTime: "", // 最小实际开工时间
        maxActualStartTime: "", // 最大实际开工时间
        minActualCompleteTime: "", // 最小实际竣工时间
        maxActualCompleteTime: "", // 最大实际竣工时间
        dateFilterLogic: "", // 时间筛选逻辑
        source_desc: "全国建筑市场监管公共服务平台", // 数据来源描述

        pageNum: 1,
        pageSize: 20,
      };
      this.time = {
        resultTime: [], // 中标时间
        contractTime: [], // 合同时间
        permitTime: [], // 施工许可时间
        reviewTime: [], // 审查时间
        planStartTime: [], // 计划开工时间
        planEndTime: [], // 计划竣工时间
        actualStartTime: [], // 实际开工时间
        actualCompleteTime: [], // 实际竣工时间
      };
      this.keywordList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  async activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .desc {
        // display: flex;
        // align-items: center;
        color: #999;

        .desc_item {
          // flex: 2;
          display: flex;
          align-items: center;
          // margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }

  /deep/.el-pagination {
    text-align: right;
    padding: 20px 30px;
  }

  /deep/.el-tabs__header {
    background-color: #fff;
    // padding: 20px;
    // padding-top: 0px;
  }

  /deep/.el-form {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px 50px;
    // padding: 30px;
    // padding-left: 20px;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }

  /deep/.list {
    font-size: 16px;
    background-color: #fff;
    .desc {
      font-size: 14px;
    }
  }

  /deep/.total {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /deep/.el-date-editor .el-range-separator {
    width: 6%;
  }
}
</style>
